import { Modal } from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';
import { ResetCSS } from 'common/assets/css/style';
import { theme } from 'common/theme/appclassic';
import Seo from 'components/seo';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppClassic/appClassic.style';
import Banner from 'containers/AppClassic/Banner';
import Customer from 'containers/AppClassic/Customer';
import Faq from 'containers/AppClassic/Faq';
import Features from 'containers/AppClassic/Features';
import FeatureTab from 'containers/AppClassic/FeatureTab';
import JoinTrail from 'containers/AppClassic/JoinTrail';
import KeyFeatures from 'containers/AppClassic/KeyFeatures';
import Navbar from 'containers/AppClassic/Navbar';
import Testimonial from 'containers/AppClassic/Testimonial';
import Pricing from 'containers/AppCreative/Pricing';
import Footer from 'containers/AppCreative2/Footer';
import React from 'react';
import Helmet from 'react-helmet';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';

const structuredData = {
  "@context": "https://schema.org",
  "@type": "Product",
  "name": "H2Zero Water Tank Monitoring",
  "description": "Smart water tank monitoring system in Bermuda",
  "brand": {
    "@type": "Brand",
    "name": "H2Zero Bermuda"
  },
  "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/InStock",
      "areaServed": "Bermuda",
      "price": "548.00",
      "priceCurrency": "BMD",
      "itemCondition": "https://schema.org/NewCondition"
  },
  "category": "Water Management System",
  "sameAs": [
    "https://www.instagram.com/h2zerobm"
  ],
  "image": {
    "@type": "ImageObject",
    "url": "/h2zero-logo.png",
    "contentUrl": "/h2zero-logo.png",
    "caption": "H2Zero Water Tank Monitoring System"
  },
  "url": "https://h2zero.bm",
  "additionalProperty": [{
    "@type": "PropertyValue",
    "name": "Features",
    "value": "Real-time monitoring, Leak detection, Consumption analytics"
}, {
    "@type": "PropertyValue",
    "name": "Coverage",
    "value": "All parishes in Bermuda"
},{
    "@type": "PropertyValue",
    "name": "Mobile app availability",
    "value": "IOS and Android"
}, {
    "@type": "PropertyValue",
    "name": "Installation",
    "value": "Professional installation included"
}]
};

const AppClassic = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="H2Zero | Tank Water Level Monitoring Service" />
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Modal />
            <Banner />
            <Customer />
            <KeyFeatures />
            <Features />
            <FeatureTab />
            <Pricing />
            <Testimonial />
            <Faq />
            <JoinTrail />
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};
export default AppClassic;
